import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function App() {
  let [params] = useSearchParams();
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("" || params.get("prompt"));
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [image, setImage] = useState(null);
  const [photosOnly, setPhotosOnly] = useState(
    params.get("type") === "photo" ? true : false
  );

  useEffect(() => {
    fetchData(page);
  }, [page]);

  useEffect(() => {
    setData([]);
    setPage(1);
  }, [searchValue, photosOnly]);

  const fetchData = () => {
    setLoadingData(true);
    console.log(searchValue !== null);
    if (searchValue !== "" && searchValue !== null) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/search/${searchValue}?page=${page}&type=${
            photosOnly ? "photo" : ""
          }`
        )
        .then((res) => {
          setData([...data, ...res.data.data]);
          setLoadingData(false);
        })
        .catch(() => {
          setData([]);
          console.log("An error occured");
          setLoadingData(false);
        });
    } else {
      console.log('No search');
    }
  };

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [data]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (searchValue !== "") {
      fetchData();
      navigate(`?prompt=${searchValue}${photosOnly ? "&type=photo" : ""}`);
    }
  };

  const contributorDetails = async (url) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/contributor-details?url=${url}`
    );
    navigate(`/${res.data.url}`);
    // getContributorDetails(res.data.url);
  };

  // const getContributorDetails = async () => [
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_SERVER_URL}/contributor/${contributor}?prompt=${searchValue}`
  //     )
  //     .then((res) => {
  //       setContributorData(res.data.contributorData);
  //       setData(res.data.data);
  //     }),
  // ];

  const clickHandler = async (url) => {
    setLoading(true);
    const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/get-img`, {
      link: url,
    });

    const imgName = url.slice(41) || "img";
    setImage(res.data);
    fetch(res.data)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${imgName}.jpeg`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  return (
    <div className="container">
      {loading && (
        <div className="loading-screen">
          {!image && <h1>Downloading...</h1>}
          {image && (
            <div
              className="final-img"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={image} alt="Final image" width="90%" />
              <button
                onClick={() => {
                  setImage(null);
                  setLoading(false);
                }}
                style={{
                  width: "90%",
                  marginTop: "10px",
                }}
              >
                &times;
              </button>
            </div>
          )}
        </div>
      )}
      <form
        onSubmit={submitHandler}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="form-container">
          <input
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            placeholder="Search for images..."
            value={searchValue}
          ></input>
          <button>Search</button>
        </div>
        <div
          className="options"
          style={{ padding: "10px", display: "flex", justifyContent: "center" }}
        >
          <div
            className="radio-container"
            style={{ display: "flex", alignItems: "center" }}
          >
            <label htmlFor="photo">Photo only</label>
            <input
              type={"checkbox"}
              id="photo"
              defaultChecked={photosOnly}
              onChange={() => {
                setPhotosOnly(!photosOnly);
              }}
            />
          </div>
        </div>
      </form>
      <div className="image-container">
        {data.map((e) => {
          return (
            <div className="img">
              <button
                className="options-btn"
                onClick={() => {
                  contributorDetails(e.url);
                }}
              >
                <i class="fa-solid fa-user"></i>
              </button>
              <div className="portfolio-container"></div>
              <img
                src={e.img}
                alt={e.img}
                key={e.img}
                width="100%"
                onClick={() =>
                  clickHandler("https://www.shutterstock.com" + e.url)
                }
                style={{ cursor: "pointer" }}
              />
            </div>
          );
        })}
        {loadingData && (
          <h2>
            <i class="fa-light fa-spinner-third"></i>
          </h2>
        )}
      </div>
    </div>
  );
}

export default App;
