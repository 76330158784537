import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

function Contributor() {
  let { contributor } = useParams();
  let [params] = useSearchParams();
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState(
    params.get("prompt") === null ? "" : params.get("prompt")
  );
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [image, setImage] = useState(null);
  const [contributorData, setContributorData] = useState(null);

  useEffect(() => {
    console.log(contributor);
    fetchData(page);
  }, [page]);

  useEffect(() => {
    setData([]);
    setPage(1);
  }, [searchValue]);

  const fetchData = () => {
    setLoadingData(true);
    // console.log(searchValue !== null);
    console.log("Got fetch");
    // if (searchValue !== "" && searchValue !== null) {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/contributor/${contributor}?prompt=${searchValue}&page=${page}`
      )
      .then((res) => {
        setData([...data, ...res.data.data]);
        setContributorData(res.data.contributorData);
        setLoadingData(false);
      })
      .catch(() => {
        setData([]);
        console.log("An error occured");
        setLoadingData(false);
      });
    // } else {
    //   console.log('No search');
    // }
  };

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [data]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (searchValue !== "") {
      fetchData();
      navigate(`?prompt=${searchValue}`);
    }
  };

  // const getContributorDetails = async () => [
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_SERVER_URL}/contributor/${contributor}?prompt=${searchValue}`
  //     )
  //     .then((res) => {
  //       setContributorData(res.data.contributorData);
  //       setData(res.data.data);
  //     }),
  // ];

  const clickHandler = async (url) => {
    setLoading(true);
    const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/get-img`, {
      link: url,
    });

    const imgName = url.slice(41) || "img";
    setImage(res.data);
    fetch(res.data)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${imgName}.jpeg`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  return (
    <div className="container">
      {loading && (
        <div className="loading-screen">
          {!image && <h1>Downloading...</h1>}
          {image && (
            <div
              className="final-img"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={image} alt="Final image" width="90%" />
              <button
                onClick={() => {
                  setImage(null);
                  setLoading(false);
                }}
                style={{
                  width: "90%",
                  marginTop: "10px",
                }}
              >
                &times;
              </button>
            </div>
          )}
        </div>
      )}
      {contributorData !== null && (
        <div className="user-details" style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px"
        }}>
          <div className="pfp" style={{ backgroundImage: `url(${contributorData.pfp !== undefined && contributorData.pfp.charAt(0) == '/' ? 'https://ak.picdn.net' + contributorData.pfp : 'https://ak.picdn.net/' + contributorData.pfp})`, width: '100px', height: '100px', borderRadius: '50%', backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
          <h3>{contributorData.name}</h3>
        </div>
      )}
      <form
        onSubmit={submitHandler}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="form-container">
          <input
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            placeholder="Search for images..."
            value={searchValue}
          ></input>
          <button>Search</button>
        </div>
        <div
          className="options"
          style={{ padding: "10px", display: "flex", justifyContent: "center" }}
        ></div>
      </form>
      <div className="image-container">
        {data.map((e) => {
          return (
            <div className="img" key={e.url + Math.random()}>
              <div className="portfolio-container"></div>
              <img
                src={e.img}
                alt={e.img}
                key={e.img}
                width="100%"
                onClick={() =>
                  clickHandler("https://www.shutterstock.com" + e.url)
                }
                style={{ cursor: "pointer" }}
              />
            </div>
          );
        })}
        {loadingData && (
          <h2>
            <i className="fa-light fa-spinner-third"></i>
          </h2>
        )}
      </div>
    </div>
  );
}

export default Contributor;
